import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This is a `}<inlineCode parentName="p">{`<PageDescription>`}</inlineCode>{` component. It is generally used for intro text at
the top of the page using the
`}<a parentName="p" {...{
          "href": "https://www.carbondesignsystem.com/guidelines/typography/expressive"
        }}>{`type token`}</a>{`
`}<inlineCode parentName="p">{`expressive-heading-03`}</inlineCode>{`.`}</p>
    </PageDescription>
    <h2>{`Notes`}</h2>
    <p>{`For most pages, we recommend starting with a `}<inlineCode parentName="p">{`PageDescription`}</inlineCode>{` followed by
`}<inlineCode parentName="p">{`AnchorLinks`}</inlineCode>{` if the content is long enough. Please note that this component,
like all MDX components, is picky about white space, the line break above and
below your content is required, and if you even have an extra space on the empty
lines above/below it won’t render correctly.`}</p>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-markup",
        "metastring": "path=components/PageDescription/PageDescription.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/PageDescription",
        "path": "components/PageDescription/PageDescription.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/PageDescription"
      }}>{`<PageDescription>

Lorem ipsum dolor sit amet, **consectetur adipiscing elit**, sed do eiusmod tempor _incididunt_ ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.

</PageDescription>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      